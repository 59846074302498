import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { CaptureConsole, ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fd945d388c8d045e990da5d8485bc6433@o1325791.ingest.sentry.io\u002F6585374",
    environment:"production",
    debug:false,
    release:"3f304fb",
    ignoreErrors:["NotSupportedError: Failed to load because no supported source was found.","The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.","NotAllowedError: play() failed because the user didn't interact with the document first.","TypeError: Failed to fetch","Cannot read property 'join' of null","Non-Error promise rejection captured","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications","malformed JSON response","GSI_LOGGER",new RegExp("^U$", ""),new RegExp("^V$", ""),new RegExp("^timeout of *.*ms exceeded$", "")],
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new CaptureConsole({ levels:["error"] }),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
