import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _15e99c0c = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _9ff2a32e = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _4d37f08e = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _32a5be4e = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _02b51f8c = () => interopDefault(import('../pages/buy-solo-ads.vue' /* webpackChunkName: "pages/buy-solo-ads" */))
const _49d6cd0f = () => interopDefault(import('../pages/buy-solo-ads/index.vue' /* webpackChunkName: "pages/buy-solo-ads/index" */))
const _0de9337d = () => interopDefault(import('../pages/buy-solo-ads/niche/_slug.vue' /* webpackChunkName: "pages/buy-solo-ads/niche/_slug" */))
const _605d3e5e = () => interopDefault(import('../pages/dev-login.vue' /* webpackChunkName: "pages/dev-login" */))
const _727e699b = () => interopDefault(import('../pages/filter.vue' /* webpackChunkName: "pages/filter" */))
const _65e7cdc9 = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _3ec8c24e = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _98bc535e = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _40cafe49 = () => interopDefault(import('../pages/forum/comment/_id.vue' /* webpackChunkName: "pages/forum/comment/_id" */))
const _05b3753a = () => interopDefault(import('../pages/forum/_section/index.vue' /* webpackChunkName: "pages/forum/_section/index" */))
const _7fe1aa6d = () => interopDefault(import('../pages/forum/_section/_idSlug.vue' /* webpackChunkName: "pages/forum/_section/_idSlug" */))
const _309070b8 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _1c5df9b2 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _42255864 = () => interopDefault(import('../pages/help/support.vue' /* webpackChunkName: "pages/help/support" */))
const _6de0e514 = () => interopDefault(import('../pages/help/a/_plink.vue' /* webpackChunkName: "pages/help/a/_plink" */))
const _637164b2 = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _98c508ca = () => interopDefault(import('../pages/help/_unregistered/_plink.vue' /* webpackChunkName: "pages/help/_unregistered/_plink" */))
const _5e12c096 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e0b70de4 = () => interopDefault(import('../pages/logo.vue' /* webpackChunkName: "pages/logo" */))
const _689649d5 = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _56e908ac = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _460a4799 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4ca6cb8b = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _b691286a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3dedd8f6 = () => interopDefault(import('../pages/search/_unregistered.vue' /* webpackChunkName: "pages/search/_unregistered" */))
const _9ec1818a = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _2fdae3e8 = () => interopDefault(import('../pages/solo-ads/index.vue' /* webpackChunkName: "pages/solo-ads/index" */))
const _2fe8b196 = () => interopDefault(import('../pages/solodeals.vue' /* webpackChunkName: "pages/solodeals" */))
const _a82d9c10 = () => interopDefault(import('../pages/solodeals/index.vue' /* webpackChunkName: "pages/solodeals/index" */))
const _5d56a6ef = () => interopDefault(import('../pages/solodeals/price.vue' /* webpackChunkName: "pages/solodeals/price" */))
const _7cfbe672 = () => interopDefault(import('../pages/solodeals/search.vue' /* webpackChunkName: "pages/solodeals/search" */))
const _0e8f3c25 = () => interopDefault(import('../pages/solodeals/_post.vue' /* webpackChunkName: "pages/solodeals/_post" */))
const _5b1fa716 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _473c88fc = () => interopDefault(import('../pages/test-apple.vue' /* webpackChunkName: "pages/test-apple" */))
const _fb81f22a = () => interopDefault(import('../pages/test-cases.vue' /* webpackChunkName: "pages/test-cases" */))
const _01c15fde = () => interopDefault(import('../pages/test-cases/page1.vue' /* webpackChunkName: "pages/test-cases/page1" */))
const _01cf775f = () => interopDefault(import('../pages/test-cases/page2.vue' /* webpackChunkName: "pages/test-cases/page2" */))
const _92bdb2a8 = () => interopDefault(import('../pages/test-owl.vue' /* webpackChunkName: "pages/test-owl" */))
const _3021be7c = () => interopDefault(import('../pages/udimi-reviews-video/index.vue' /* webpackChunkName: "pages/udimi-reviews-video/index" */))
const _1b76b530 = () => interopDefault(import('../pages/competition/hall-of-fame.vue' /* webpackChunkName: "pages/competition/hall-of-fame" */))
const _4b3891ba = () => interopDefault(import('../pages/notifications/instantunsubscribe.vue' /* webpackChunkName: "pages/notifications/instantunsubscribe" */))
const _f60326e6 = () => interopDefault(import('../pages/notifications/settings.vue' /* webpackChunkName: "pages/notifications/settings" */))
const _4c6e61c0 = () => interopDefault(import('../pages/profile/custom-route/recommends.vue' /* webpackChunkName: "pages/profile/custom-route/recommends" */))
const _d729c502 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _298607b7 = () => interopDefault(import('../pages/competition/movers-and-shakers/_month.vue' /* webpackChunkName: "pages/competition/movers-and-shakers/_month" */))
const _09970113 = () => interopDefault(import('../pages/feeds/post/_uid.vue' /* webpackChunkName: "pages/feeds/post/_uid" */))
const _18ea6c6a = () => interopDefault(import('../pages/articles/_plink.vue' /* webpackChunkName: "pages/articles/_plink" */))
const _7ae2855d = () => interopDefault(import('../pages/ratings/_month.vue' /* webpackChunkName: "pages/ratings/_month" */))
const _77d42d84 = () => interopDefault(import('../pages/restore/_token.vue' /* webpackChunkName: "pages/restore/_token" */))
const _37f67583 = () => interopDefault(import('../pages/reviews/_permalink.vue' /* webpackChunkName: "pages/reviews/_permalink" */))
const _ac50c838 = () => interopDefault(import('../pages/share/_uid.vue' /* webpackChunkName: "pages/share/_uid" */))
const _6df8cec5 = () => interopDefault(import('../pages/solo-ads/_plink.vue' /* webpackChunkName: "pages/solo-ads/_plink" */))
const _e269309a = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _50b0655e = () => interopDefault(import('../pages/udimi-reviews-video/_page.vue' /* webpackChunkName: "pages/udimi-reviews-video/_page" */))
const _7dda0ee7 = () => interopDefault(import('../pages/solo/_uid/_anySubRoute.vue' /* webpackChunkName: "pages/solo/_uid/_anySubRoute" */))
const _e71936ac = () => interopDefault(import('../pages/_unregistered/index.vue' /* webpackChunkName: "pages/_unregistered/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _15e99c0c,
    name: "affiliate"
  }, {
    path: "/affiliates",
    component: _9ff2a32e,
    name: "affiliates"
  }, {
    path: "/app",
    component: _4d37f08e,
    name: "app"
  }, {
    path: "/awards",
    component: _32a5be4e,
    name: "awards"
  }, {
    path: "/buy-solo-ads",
    component: _02b51f8c,
    children: [{
      path: "",
      component: _49d6cd0f,
      name: "buy-solo-ads"
    }, {
      path: "niche/:slug?",
      component: _0de9337d,
      name: "buy-solo-ads-niche-slug"
    }]
  }, {
    path: "/dev-login",
    component: _605d3e5e,
    name: "dev-login"
  }, {
    path: "/filter",
    component: _727e699b,
    name: "filter"
  }, {
    path: "/forgot",
    component: _65e7cdc9,
    name: "forgot"
  }, {
    path: "/forum",
    component: _3ec8c24e,
    children: [{
      path: "",
      component: _98bc535e,
      name: "forum"
    }, {
      path: "comment/:id?",
      component: _40cafe49,
      name: "forum-comment-id"
    }, {
      path: ":section",
      component: _05b3753a,
      name: "forum-section"
    }, {
      path: ":section/:idSlug",
      component: _7fe1aa6d,
      name: "forum-section-idSlug"
    }]
  }, {
    path: "/help",
    component: _309070b8,
    children: [{
      path: "",
      component: _1c5df9b2,
      name: "help"
    }, {
      path: "support",
      component: _42255864,
      name: "help-support"
    }, {
      path: "a/:plink?",
      component: _6de0e514,
      name: "help-a-plink"
    }, {
      path: ":category",
      component: _637164b2,
      name: "help-category"
    }, {
      path: ":unregistered/:plink?",
      component: _98c508ca,
      name: "help-unregistered-plink"
    }]
  }, {
    path: "/login",
    component: _5e12c096,
    name: "login"
  }, {
    path: "/logo",
    component: _e0b70de4,
    name: "logo"
  }, {
    path: "/logos",
    component: _689649d5,
    name: "logos"
  }, {
    path: "/prime",
    component: _56e908ac,
    name: "prime"
  }, {
    path: "/profile",
    component: _460a4799,
    name: "profile"
  }, {
    path: "/reviews",
    component: _4ca6cb8b,
    name: "reviews"
  }, {
    path: "/search",
    component: _b691286a,
    name: "search",
    children: [{
      path: ":unregistered?",
      component: _3dedd8f6,
      name: "search-unregistered"
    }]
  }, {
    path: "/signup",
    component: _9ec1818a,
    name: "signup"
  }, {
    path: "/solo-ads",
    component: _2fdae3e8,
    name: "solo-ads"
  }, {
    path: "/solodeals",
    component: _2fe8b196,
    children: [{
      path: "",
      component: _a82d9c10,
      name: "solodeals"
    }, {
      path: "price",
      component: _5d56a6ef,
      name: "solodeals-price"
    }, {
      path: "search",
      component: _7cfbe672,
      name: "solodeals-search"
    }, {
      path: ":post",
      component: _0e8f3c25,
      name: "solodeals-post"
    }]
  }, {
    path: "/test",
    component: _5b1fa716,
    name: "test"
  }, {
    path: "/test-apple",
    component: _473c88fc,
    name: "test-apple"
  }, {
    path: "/test-cases",
    component: _fb81f22a,
    name: "test-cases",
    children: [{
      path: "page1",
      component: _01c15fde,
      name: "test-cases-page1"
    }, {
      path: "page2",
      component: _01cf775f,
      name: "test-cases-page2"
    }]
  }, {
    path: "/test-owl",
    component: _92bdb2a8,
    name: "test-owl"
  }, {
    path: "/udimi-reviews-video",
    component: _3021be7c,
    name: "udimi-reviews-video"
  }, {
    path: "/competition/hall-of-fame",
    component: _1b76b530,
    name: "competition-hall-of-fame"
  }, {
    path: "/notifications/instantunsubscribe",
    component: _4b3891ba,
    name: "notifications-instantunsubscribe"
  }, {
    path: "/notifications/settings",
    component: _f60326e6,
    name: "notifications-settings"
  }, {
    path: "/profile/custom-route/recommends",
    component: _4c6e61c0,
    name: "profile-custom-route-recommends"
  }, {
    path: "/",
    component: _d729c502,
    name: "index"
  }, {
    path: "/competition/movers-and-shakers/:month?",
    component: _298607b7,
    name: "competition-movers-and-shakers-month"
  }, {
    path: "/feeds/post/:uid?",
    component: _09970113,
    name: "feeds-post-uid"
  }, {
    path: "/articles/:plink?",
    component: _18ea6c6a,
    name: "articles-plink"
  }, {
    path: "/ratings/:month?",
    component: _7ae2855d,
    name: "ratings-month"
  }, {
    path: "/restore/:token?",
    component: _77d42d84,
    name: "restore-token"
  }, {
    path: "/reviews/:permalink",
    component: _37f67583,
    name: "reviews-permalink"
  }, {
    path: "/share/:uid?",
    component: _ac50c838,
    name: "share-uid"
  }, {
    path: "/solo-ads/:plink?",
    component: _6df8cec5,
    name: "solo-ads-plink"
  }, {
    path: "/token/:token?",
    component: _e269309a,
    name: "token-token"
  }, {
    path: "/udimi-reviews-video/:page?",
    component: _50b0655e,
    name: "udimi-reviews-video-page"
  }, {
    path: "/solo/:uid?/:anySubRoute?",
    component: _7dda0ee7,
    name: "solo-uid-anySubRoute"
  }, {
    path: "/:unregistered",
    component: _e71936ac,
    name: "unregistered"
  }, {
    path: "/u/:hash",
    component: _4b3891ba,
    name: "u-hash"
  }, {
    path: "/u/settings/:hash/:it",
    component: _f60326e6,
    name: "u-settings-hash"
  }, {
    path: "/a/:uid",
    component: _d729c502,
    name: "affiliate-regular"
  }, {
    path: "/prime/:uid([a-zA-Z0-9]{5})",
    component: _d729c502,
    name: "affiliate-prime"
  }, {
    path: "/p/:uid_profile",
    component: _460a4799,
    name: "profile-index"
  }, {
    path: "/p/:uid_profile/recommends",
    component: _4c6e61c0,
    name: "profile-recommends"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
