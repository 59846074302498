//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import LayDesktopFooter from '~/components/layouts/LayDesktopFooter'
import LayDesktopHeader from '~/components/layouts/LayDesktopHeader'

export default defineComponent({
    components: {
        LayDesktopHeader,
        LayDesktopFooter
    },
    computed: {
        isNoFooter () {
            return ['help', 'help-category', 'help-a-plink', 'reviews-permalink', 'feeds-post-uid', 'app'].includes(this.$route.name) || this.isBlogPage
        },
        appDesktopMainClass () {
            if (this.isHelpPage) {
                return 'app-desktop-main m-help-layout'
            } else if (this.isBlogPage) {
                return 'app-desktop-main m-blog-layout'
            } else {
                return 'app-desktop-main'
            }
        },
        isHelpPage () {
            return this.$route.matched.map(r => r.path).includes('/help')
        },
        isBlogPage () {
            return ['solo-ads', 'solo-ads-plink'].includes(this.$route.name)
        }
    }
})
